import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    // Redirect to the desired URL
    window.location.replace("https://www.reghero.org");
  }, []);

  // Optionally, you can return null or some placeholder content while the redirect happens
  return null;
}

export default App;
